@function serif-font-stack($fontName) {
    @return #{$fontName}, ui-serif, 'Times New Roman', Times, serif;
}

@function sans-serif-font-stack($fontName) {
    @return #{$fontName}, ui-sans-serif, system-ui, 'Helvetica Neue', Arial, sans-serif;
}

$fontPath: '../fonts' !default;

$butler: serif-font-stack('Butler');
$raleway: sans-serif-font-stack('Raleway');
$rawline: sans-serif-font-stack('Rawline');

$serif: $butler;
$sans: $raleway;
$numbers: $rawline;

$font-family: (
    'serif': $serif,
    'sans': $sans,
    'numbers': $numbers,
);

$fw-light: 300;
$fw-normal: 400;
$fw-semibold: 600;
$fw-bold: 700;

$font-weight: (
    'light': $fw-light,
    'normal': $fw-normal,
    'semibold': $fw-semibold,
    'bold': $fw-bold,
);

$fs-100: 0.5rem; // 8px
$fs-200: 0.75rem; // 12px
$fs-300: 0.875rem; // 14px
$fs-400: 1rem; // 16px
$fs-500: 1.125rem; // 18px
$fs-600: 1.25rem; // 20px
$fs-700: 1.5rem; // 24px
$fs-800: 1.875rem; // 30px

$font-size: (
    100: $fs-100,
    200: $fs-200,
    300: $fs-300,
    400: $fs-400,
    500: $fs-500,
    600: $fs-600,
    700: $fs-700,
    800: $fs-800,
);

$lh-400: 1;
$lh-500: 1.15;
$lh-600: 1.25;
$lh-700: 1.5;
$lh-800: 1.623;
$lh-900: 2;

$line-height: (
    400: $lh-400,
    500: $lh-500,
    600: $lh-600,
    700: $lh-700,
    800: $lh-800,
    900: $lh-900,
);

$heading-levels: 1 2 3 4 5;
