@use '~@keyliving/component-lib/dist/scss/vars';
@use '~@keyliving/component-lib/dist/scss/utils';
@use '~@keyliving/component-lib/dist/scss/mixins' as *;

.wrapper {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    background-color: #fafafa;
    justify-content: center;
}

.container {
    width: 100%;
    max-width: 450px;
    padding-right: 1rem;
    padding-left: 1rem;
    margin: 0 auto;
}

.content-wrapper {
    padding: 2rem 1rem 1rem;
    background-color: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 15%), 0 2px 4px -2px rgb(0 0 0 / 15%);

    @include screen('lg') {
        padding: 2rem;
    }
}

.logo {
    margin: 2rem 0;
}
