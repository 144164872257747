@use '~@keyliving/component-lib/dist/scss/utils';

.powered-by {
    font-size: 1rem;
    color: utils.color('grey', 300);
    text-align: center;
}

.key-logo {
    margin-top: 0.5rem;
    margin-right: auto;
    margin-left: auto;
}
