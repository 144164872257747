@use 'sass:map';
@use '../vars/breakpoints' as *;

@mixin screen($size) {
    @if not map.has-key($breakpoints, $size) {
        @error 'breakpoint \"#{$size}\" is invalid.';
    }

    @media only screen and (min-width: map.get($breakpoints, $size)) {
        @content;
    }
}

// EXAMPLES
// .something {
//   padding: 1rem;

//   @include screen('sm') {
//     padding: 0.5rem 1rem;
//   }
// }

// @include screen('sm') {
//   .something {
//     padding: 0.5rem 1rem;
//   }
// }
