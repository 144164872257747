@use '~@keyliving/component-lib/dist/scss/utils' as *;

.wrapper {
    display: grid;
    width: 100%;
    height: 100%;
    justify-items: center;
    align-items: center;
}

.loading-indicator {
    color: color('royal', 500);
}
