@use '~@keyliving/component-lib/dist/scss/vars' as *;

.wrapper {
    text-align: center;
}

.status {
    font-family: $numbers;
    font-size: 5rem;
    font-weight: $fw-bold;
}

.message {
    font-size: $fs-600;
    line-height: 1.5;
}
