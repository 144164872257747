@use '../vars/fonts';

@mixin heading-base {
    font-style: normal;
    line-height: fonts.$lh-500;
}

@mixin content {
    @each $size in fonts.$heading-levels {
        .h#{$size},
        h#{$size} {
            @include heading-base;

            margin-bottom: 0.5em;
            font-size: var(--h#{$size});

            &:not(:first-child) {
                margin-top: 1.1em;
            }
        }
    }

    p {
        line-height: fonts.$lh-700;
    }

    ul,
    ol,
    p {
        & + p {
            margin-top: 1rem;
        }
    }

    ul,
    ol {
        padding-left: 2rem;
        margin-top: 1rem;

        li + li {
            margin-top: 1rem;
        }
    }

    ul {
        list-style: disc;
    }

    ol {
        list-style: decimal;
    }
}
